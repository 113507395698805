/*eslint-disable jsx-a11y/control-has-associated-label*/

import { css } from "@emotion/core";
import PropTypes from "prop-types";
import React from "react";
import { rhythm } from "../utils/typography";

const styles = css`
  background-color: #03a9f4;
  border-radius: 10px;
  color: white;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  margin-left: -1.3125rem;
  margin-right: -1.3125rem;
  padding-left: 1.3125rem;
  padding-right: 1.3125rem;
  * {
    margin-block-end: 0;
    margin-block-start: 0;
  }
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  form:last-child {
    padding-bottom: ${rhythm(1)};
  }
  input {
    background-color: transparent;
    border: 1px solid rgba(255 255 255 / 30%);
    border-radius: 4px;
    color: white;
    font-size: 0.75em;
    outline: none;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    width: calc(40% - ${rhythm(0.5)});
  }
  input::selection {
    background-color: rgba(0 0 0 / 30%);
  }
  input:hover {
    border-color: transparent;
    box-shadow: 0 0 1px 2px rgba(255 255 255 / 50%);
  }
  input:focus {
    border-color: transparent;
    box-shadow: 0 0 1px 2px white;
  }
  input[type="submit"] {
    border: 1px solid rgba(255 255 255 / 50%);
    width: 20%;
  }
  input[type="submit"]:hover {
    border-color: transparent;
    box-shadow: 0 0 1px 2px 1px 2px rgba(255 255 255 / 50%);
  }
  input[type="submit"]:focus {
    border-color: transparent;
    box-shadow: 0 0 1px 2px white;
  }
  p {
    padding-bottom: ${rhythm(0.5)};
    padding-top: ${rhythm(0.5)};
  }
  @media (max-width: 672px) {
    border-radius: 0;
    input[type] {
      margin-top: ${rhythm(0.5)};
      width: 100%;
    }
    input:first-of-type {
      margin-top: 0;
    }
  }
  .banner {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }
  .banner a {
    color: white;
    font-size: 0.5em;
  }
`;

function reducer(state = {}, action) {
  switch (action.type) {
    case "submitted":
      if (state.status === "submitted") {
        return state;
      }
      return {
        ...state,
        payload: action.payload,
        status: action.type,
      };
    case "succeeded":
      return {
        ...state,
        message: "Thanks, I've just sent an email with the confirmation link.",
        response: action.response,
        status: action.type,
      };
    case "failed":
      return {
        ...state,
        error: action.error,
        message: "Whoops. Something went wrong.",
        status: action.type,
      };
    default:
      return state;
  }
}

function Subscribe({ tags }) {
  const [state, dispatch] = React.useReducer(reducer, {
    payload: undefined,
    prompt: "",
    status: "idle",
  });
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");

  React.useEffect(() => {
    if (!state.payload) {
      return;
    }
    fetch("https://app.convertkit.com/forms/1366459/subscriptions", {
      method: "post",
      body: JSON.stringify({
        email_address: state.payload.email,
        first_name: state.payload.name,
        tags,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (response) => dispatch({ type: "succeeded", response }),
        (error) => dispatch({ type: "failed", error })
      );
  }, [state.payload, tags]);

  return (
    <div css={styles}>
      <div className="banner">
        <p>Join my weekly newsletter</p>
        <p>
          <a href="/privacy" target="_blank">
            Privacy Policy
          </a>
        </p>
      </div>
      <form
        autoComplete="off"
        onSubmit={(event) => {
          event.preventDefault();
          dispatch({ type: "submitted", payload: { name, email } });
          return false;
        }}
      >
        <input
          type="text"
          placeholder="First name"
          value={name}
          spellCheck={false}
          onChange={(event) => setName(event.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          required
          value={email}
          spellCheck={false}
          onChange={(event) => setEmail(event.target.value)}
        />
        <input
          type="submit"
          value={state.status === "submitted" ? "Subscribing..." : "Subscribe"}
        />
      </form>
      {state.message && <p>{state.message}</p>}
    </div>
  );
}

Subscribe.defaultProps = {
  tags: [],
};

Subscribe.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};

export default Subscribe;
